import React from 'react';
import {
  Card, Col, Divider, Row, Space, Typography,
} from 'antd';
import { FcAbout, FcCallback } from 'react-icons/all';
import { BaseLayout } from '../components/layout/base/base-layout';
import { SEO } from '../components/layout/seo';
import { HelpCard } from '../components/cards/help-card/help-card';
import { LinkCard } from '../components/cards/link-card/link-card';
import { Testimonials } from '../components/layout/sections/testimonials/testimonials';
import { useShop } from '../graphql/static-hooks/useShop';
import { GetSupportCard } from '../components/cards/action-card/get-support-card';
import { ViewFavouritesCard } from '../components/cards/action-card/view-favourites-card';
import { StartSearchCard } from '../components/cards/action-card/start-search-card';

const {
  Title,
  Paragraph,
} = Typography;

const SupportPage = () => {
  const shop = useShop();

  return (
    <BaseLayout>
      <SEO title="Support" />
      <Title level={4} className="text-center">
        The Top Event Professional Use
        {' '}
        {shop.name}
      </Title>
      <Paragraph className="text-center">
        Built by event professionals, for event professionals.
        Our mission is to help you save time.
      </Paragraph>
      <Divider />
      <Row gutter={[32, 32]}>
        <Col flex="1 1 250px">
          <StartSearchCard />
        </Col>
        <Col flex="1 1 250px">
          <ViewFavouritesCard />
        </Col>
        <Col flex="1 1 250px">
          <GetSupportCard />
        </Col>
      </Row>
      <Divider />
      <Testimonials />
      <Row gutter={[32, 32]} justify="center">
        <Col flex="2 1 250px">
          <LinkCard
            title="FAQ"
            overlay="blue"
            link="/about/FAQ"
          />
        </Col>
        <Col flex="1 1 250px">
          <HelpCard />
        </Col>
        <Col flex="1 1 200px">
          <Space direction="vertical">
            <Card bordered={false}>
              <Card.Meta
                avatar={<FcCallback />}
                title="Phone"
                description={shop.companyAddress?.phone || '+34 931 785 300'}
              />
            </Card>
            <Card bordered={false}>
              <Card.Meta
                avatar={<FcAbout />}
                title="Email"
                description={shop.defaultMailSenderAddress || 'contact@akommo.com'}
              />
            </Card>
          </Space>
        </Col>
      </Row>
    </BaseLayout>
  );
};

export default SupportPage;
