import { Card, Typography } from 'antd';
import React from 'react';
import { FcOnlineSupport } from 'react-icons/all';
import { Link } from 'gatsby';

const { Title } = Typography;

export const GetSupportCard = () => (
  <Card hoverable bordered={false}>
    <Link to="/support">
      <Card.Meta
        avatar={<FcOnlineSupport />}
        title={<Title level={4}>Get Expert Support</Title>}
        description="
          Contact suppliers directly or send an inquiry through us.
          We provide extensive support at no extra cost.
        "
      />
    </Link>
  </Card>
);
